<template>
  <!-- 日历日程页 -->
  <el-card>
    <div class="calendar">
      <!-- <Tips></Tips> -->

      <!-- 开放日程/修改开放日程 -->
      <div class="opendiv" >
        <!-- 开放日程 -->
        <div class="changeCal">统计</div>
        <div class="top">
          <!--日期范围 -->
          <div>
            <span>开始日期</span>
            <span>
              <span style="width:100%;display:inline-block;">
                <el-date-picker
                  v-model="statisticsTime"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </span>
            </span>
          </div>
          <!-- 选择项目（课程关联） -->
          <div>
            <span>选择项目</span>
            <span>
              <el-select v-model="tongjiProjectId" placeholder="请选择" class="width100 themeInput">
                <el-option
                  v-for="item in openProjectList"
                  :key="'project_'+item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </span>
          </div>
          <br />
          <div class="center">
            <el-button class="close themecancelButton" @click="closeStatistic">关 闭</el-button>
            <el-button class="confirm themeButton" type="primary" @click="getStatistics">确 定</el-button>
          </div>
        </div>

        <div class="staticInfo" v-show="showtongjiInfo">
          <div>
            <span class="staticItem">在修群组</span>
            <span class="staticItemData">{{tongjiList.zxqz}}</span>
          </div>
          <div>
            <span class="staticItem">在修人数</span>
            <span class="staticItemData">{{tongjiList.zxrs}}</span>
          </div>
          <div>
            <span class="staticItem">开放单元数</span>
            <span class="staticItemData">{{tongjiList.kfdys}}</span>
          </div>
          <div>
            <span class="staticItem">单元人数</span>
            <span class="staticItemData">{{tongjiList.dyrs}}</span>
          </div>
          <div>
            <div class="staticText">
              <div class="staticTextBox">
                <span class="staticItem">项目容量</span>
                <span class="staticItemData">{{tongjiList.xmrl}}</span>
              </div>
              <div class="staticTextBox">
                <span class="staticItem themeColor">开课率</span>
                <span class="staticItemData themeColor">{{tongjiList.kkl}}%</span>
              </div>
            </div>
            <div class="staticProgress">
              <el-progress
                color="#3E95B5"
                type="circle"
                width="50"
                :percentage="tongjiList.kkl"
                :show-text="false"
              ></el-progress>
            </div>
          </div>
          <div>
            <div class="staticText">
              <div class="staticTextBox">
                <span class="staticItem">已预约人数</span>
                <span class="staticItemData">{{tongjiList.yyy}}</span>
              </div>
              <div class="staticTextBox">
                <span class="staticItem themeColor">预约完成度</span>
                <span class="staticItemData themeColor">{{tongjiList.yywcd}}%</span>
              </div>
            </div>
            <div class="staticProgress">
              <el-progress
                color="#3E95B5"
                type="circle"
                width="50"
                :percentage="tongjiList.yywcd"
                :show-text="false"
              ></el-progress>
            </div>
          </div>
          <div>
            <div class="staticText">
              <div class="staticTextBox">
                <span class="staticItem">已完成人数</span>
                <span class="staticItemData">{{tongjiList.ywcrs}}</span>
              </div>
              <div class="staticTextBox">
                <span class="staticItem themeColor">实验完成度</span>
                <span class="staticItemData themeColor">{{tongjiList.sywcd}}%</span>
              </div>
            </div>
            <div class="staticProgress">
              <el-progress
                color="#3E95B5"
                type="circle"
                width="50"
                :percentage="tongjiList.sywcd"
                :show-text="false"
              ></el-progress>
            </div>
          </div>
        </div>
      </div>

      <!-- 开课弹窗 -->
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span class="dialog-span">{{ dialogMsg }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button class="themecancelButton" @click="dialogVisible = false">取消</el-button>
          <el-button class="primary themeButton" @click="handleExec()">删除</el-button>
        </span>
      </el-dialog>
      <!-- 日程弹窗 -->
      <el-dialog
        title="开放单元学生列表"
        :visible.sync="dialogVisibleCalendar"
        width="50%"
        :close-on-click-modal="false"
      >
        <!-- 标题 + 二维码  -->
        <div></div>
        <!-- 复制学号 + 添加学生 按钮 -->
        <div></div>
        <!-- 学生列表 表格 -->
        <div>
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="date" label="日期" width="180"></el-table-column>
            <el-table-column prop="name" label="姓名" width="180"></el-table-column>
            <el-table-column prop="address" label="地址"></el-table-column>
          </el-table>
        </div>
      </el-dialog>
      <el-dialog title="开课提示" :visible.sync="openDateDialog" width="30%">
        <span class="dialog-span">您确认要在同一时间开放多个日程吗</span>
        <span slot="footer" class="dialog-footer">
          <el-button class="themecancelButton" @click="openDateDialog = false">取消</el-button>
          <el-button class="primary themeButton" @click="confirmopen">确认</el-button>
        </span>
      </el-dialog>
    </div>
  </el-card>
</template>
<style lang="scss">
@import "@/assets/css/teacher/other/tongjiNoScope.scss";
</style>
<style lang="scss" scoped>
@import "@/assets/css/teacher/other/tongji.scss";
</style>


<script>
import Calendar from "@/assets/js/teacher/other/tongji.js";
export default {
  ...Calendar
};
</script>
