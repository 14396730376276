import { request } from "@/request/http";

export default {
  getOpenListByDate: (data) => {
    return request("GET", `/index.php/backcalendar/getOpenListByDate`, data);
  },

  getTeacherAndProject: (data) => {
    return request("GET", `/index.php/backcalendar/getTeacherAndProject`, data);
  },

  getOpenInfoById: (data) => {
    return request("GET", `/index.php/backcalendar/getOpenInfoById`, data);
  },

  getProjectListByCourseid: (data) => {
    return request("GET", `/index.php/backcalendar/getProjectListByCourseid`, data);
  },

  searchProjectList: (data) => {
    return request("POST", `/index.php/backcalendar/searchProjectList`, data);
  },

  addOpenUnit: (data) => {
    return request("GET", `/index.php/backcalendar/addOpenUnit`, data);
  },

  updateOpenUnit: (data) => {
    return request("GET", `/index.php/backcalendar/updateOpenUnit`, data);
  },

  deleteOpenUnit: (data) => {
    return request("GET", `/index.php/backcalendar/deleteOpenUnit`, data);
  },

  getTongjiData: (data) => {
    return request("POST", `/index.php/backcalendar/getTongjiData`, data);
  },
};